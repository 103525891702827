
.consultation_img_Bg {
  width: 100%;
  height: var(--height-Bg-img);
  background-image: url("/public/images/consultationBg.jpg");
}
.slider-content-consultation{
  margin: 50px auto;
  text-align: center;
  width: 50%;
}
.card-consultation{
  border: 1px solid var(--main-color);
  background-color: var(--main-color);
  width: auto !important;
  border-radius: var(--br);
}
.card-consultation>img{
  object-fit: cover;
  width: 600px;
  height: 400px; 
}
/********************** DESCRIPTION **********************/
.consultation-descript-content {
  background-color: var(--main-color);
  color: var(--five-color);
  font-weight: 400;
}
.consultation-tarif-content{
  padding: var(--paddingBlock);
  font-weight: 400;
  text-align: center;
}
.consultation-descript-body{
  max-width: 1200px;
  margin: auto;
  padding: var(--paddingBlock);
}