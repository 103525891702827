@media screen and (max-width: 380px) {
  h1,
  h2 {
    font-size: var(--fs-3) !important;
  }
  .homePage h1 i {
    font-size: var(--fs-3);
  }
  .subtitle_homePage {
    font-size: var(--fs-2);
  }
  .bandeau {
    padding: 9.3rem 1.25rem 1.25rem 1rem;
  }
  .contactButton {
    font-size: var(--fs-2);
  }
}

@media screen and (max-width: 500px) {
  .hero {
    height: 90vh;
  }
  .socialZone {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .lateralBar {
    display: none;
  }
  .contactButton {
    max-width: 320px;
  }
  .btn-testimony {
    font-size: 60%;
  }
  .form input,
  textarea {
    width: 100%;
  }
  .infoContact,
  .form {
    width: 100%;
    margin: 5px;
  }
  .slider-content-massage,
  .slider-content-consultation {
    width: 90%;
  }
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .card-massage > img,.card-consultation > img {
    height: 300px;
  }
}
@media screen and (min-width: 501px) and (max-width: 1024px) {
  .btn-testimony {
    font-size: 60%;
  }
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .slider-content-massage {
    width: 90%;
  }
}

@media screen and (max-width: 1250px) {
  .bg-img {
    background-size: contain;
    background-attachment: inherit;
    background-repeat: no-repeat;
  }
  .footer_block1,
  .footer_block2,
  .footer_block3 {
    width: 100%;
  }
  .sub-footer1 {
    display: flex;
    justify-content: center;
  }
  .footer {
    text-align: center;
  }
  .image-paragraph {
    flex-wrap: wrap;
  }
  .citations {
    flex-wrap: wrap;
  }
  .adage1,
  .adage2 {
    width: 100%;
  }
 
}
