.textContact{
  text-align: center;
  margin: var(--interSection);
}
.textContactContent {
  font-weight: 800;
  text-align: center;
  border-bottom: 1px solid #333;
  display: inline-block;
}
.zoneContact {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.infoContact {
  margin: var(--interSection);
  background-color: var(--main-color);
  height: 28.125rem;
  border-radius: var(--br);
  padding: var(--paddingBlock);
  color: var(--five-color);
  display: flex;
  flex-direction:column;
  justify-content: space-around;
}
.form {
  width: 34.375rem;
  height:28.125rem;
  padding: 1.6rem 1rem;
  background-color: var(--main-color);
  color: var(--five-color);
  text-align: center;
  border-radius: var(--br);
  margin: var(--interSection);
}
.form input,
textarea {
  border-radius: var(--br);
  width: 25rem;
  height: 3.125rem;
  border: 1px solid var(--main-color);
  margin: 0.5rem !important;
}
.form input::placeholder,
textarea::placeholder {
  color: var(--four-color);
  padding: 10px var(--fs-1) ;
}

.form input:focus,
textarea:focus {
  outline: 3px solid var(--four-color);
}
.successForm{
  display: flex;
  justify-content: center;
  border: 2px solid var(--four-color);
  padding: var(--paddingBlock);
}