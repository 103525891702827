.hero {
  text-align: center;
  height: 70vh;
  background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5)
    ),
    url("/public/images/homeBg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 50px;
}
.homePage h1 {
  color: var(--five-color);
  font-weight: 700;
  background-color: rgba(95, 79, 79, 0.8);
  border-radius: var(--br);
  padding: var(--paddingBlock);
  animation: homeTitle 3s forwards;
}
@keyframes homeTitle {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
.subtitle_homePage {
  font-weight: 600;
  font-size: var(--fs-5);
  text-transform: uppercase;
}
.box-testimony {
  border: 1px solid var(--main-color);
  border-radius: var(--br);
  padding: var(--paddingBlock);
  box-shadow: 2px 2px 2px 2px;
  background-color: var(--main-color);
  color: var(--five-color);
  margin: var(--marginBlock);
}
.btn-testimony {
  position: absolute;
  top: 120px;
  left: 10px;
  z-index: 1100;
  letter-spacing: var(--ls);
  text-transform: uppercase;
}
.btn-testimony-toggle {
  z-index: -1;
}
.title-testimony {
  text-transform: uppercase;
  color: var(--four-color);
  margin: auto;
}

.followMe {
  text-align: center;
  padding: var(--paddingBlock);
}
.followMeText {
  font-size: var(--fs-5);
}
.adages {
  background-color: var(--huit-color);
  text-align: center;
  padding: var(--paddingheightSection) 0;
}
.citations {
  display: flex;
  justify-content: center;
  margin: auto;
}
.adage1,
.adage2 {
  width: 26%;
  text-align: center;
  margin: var(--marginBlock);
}
.adageTitle {
  border-bottom: 2px solid rgba(50, 50, 50, 0.36);
  display: inline-block;
  padding-bottom: 5px;
  color: rgba(50, 50, 50, 0.36);
  font-weight: 400;
}
cite {
  color: var(--ter-color);
  font-weight: 700;
  font-style: normal;
}
