.pageML {
    padding: 3.5rem;
  }
  .pageML h1 {
    color: var(--five-color);
    background-color: var(--main-color);
    font-size: 3rem;
    text-align: center;
    font-family: var(--sec-font);
    padding: var(--paddingBlock);
  }
  .pageML h2 {
    color: var(--four-color);
  }
  .linksML1{
    color: red !important;
  }
  .linksML2{
    color: red !important;
  }
  .linksML2:visited{
    color: red !important;
  }
  .linksML3{
    color: blue !important;
  }
  .linksML3:visited{
    color: blue !important;
  }