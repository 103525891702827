.remarques {
  text-align: center;
  font-weight: 800;
  padding: var(--interSection);
}
.massage_img_Bg {
  width: 100%;
  height: var(--height-Bg-img);
  background-image: url("/public/images/massageBg.jpg");
}

/********************** SLIDER **********************/
.titleMassageSelected {
  background-color: var(--main-color);
  text-align: center;
  padding: var(--paddingBlock);
}
.slider-content-massage {
  margin: 50px auto;
  text-align: center;
  width: 50%;
}
.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}
.slick-prev,
.slick-next {
  background-color: var(--main-color) !important;
  padding: 15px !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: var(--br);
}
.slick-prev {
  left: -50px !important;
}
.slick-next {
  right: -50px !important;
}
.card-massage {
  border: 1px solid var(--main-color);
  background-color: var(--main-color);
  width: auto !important;
  border-radius: var(--br);
}
.card-massage > img {
  object-fit: cover;
  width: 600px;
  height: 500px;
}
/********************** DESCRIPTION **********************/
.massage-descript-content {
  background-color: var(--main-color);
  color: var(--five-color);
  font-weight: 400;
}
.massage-tarif-content{
  padding: var(--paddingBlock);
  font-weight: 400;
  text-align: center;
}
.massage-descript-body{
  max-width: 1200px;
  margin: auto;
  padding: var(--paddingBlock);
}
/********************** ALL MASSAGES **********************/
#comparatif td a{
  color: inherit !important;
  font-weight:bold;
  text-decoration:var(--four-color)  !important;
}
.hovered-row{
  background-color: var(--main-color);
}