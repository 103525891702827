.rebozo_img_Bg {
  width: 100%;
  height: var(--height-Bg-img);
  background-image: url("/public/images/rebozo.jpg");
}
.containerRebozo {
  text-align: center;
  color: var(--five-color);
}
.section1,
.section2 {
  background-color: var(--main-color);
  border-radius: var(--br);
  padding: var(--paddingBlock);
  margin: var(--marginBlock) 0;
}
.mentions {
  background-color: var(--five-color);
  color: var(--four-color);
  border-radius: var(--br);
  padding: var(--paddingBlock);
}
.rebozoDef,
.rebozoApport,
.rebozoDeroulement,
.rebozoBenef {
  margin: var(--separateBlock) auto;
}
