@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Roboto+Condensed:wght@300;400;700&family=Vollkorn:ital,wght@1,400;1,500;1,600;1,700;1,800&display=swap");
:root {
  --main-color: #8ba79e; /* Couleur principale */
  --sec-color: #4d4d4d; /* bkg adages accueil */
  --ter-color: #c7aa8b; /* color adages accueil */
  --four-color: #5f4f4f; /* bkg listes massages */
  --five-color: #ededed; /* écriture principale" */
  --six-color: #333; /* écriture secondaire" */
  --sept-color: #295f00; /* titre accueil */
  --huit-color: #f7f7f7; /* citations */

  /* Font Size */
  --fs-1: 1rem;
  --fs-2: 1.2rem;
  --fs-3: 1.5rem;
  --fs-4: 1.7rem;
  --fs-5: 2rem;
  --fs-6: 2.2rem;
  --ls: 0.5rem;

  /* Font */
  --main-font: "Montserrat";
  --sec-font: "Roboto Condensed";
  --ter-font: "Vollkorn";

  /* Sections */
  --interSection: 2.5rem;
  --paddingLateralSection: 1.25rem;
  --paddingheightSection: 5rem;

  /* Cadres */
  --br: 1.25rem;
  --separateBlock: 4rem;
  --borderBlock: 2px solid #ededed;
  --paddingBlock: 1.25rem;
  --marginBlock: 1.25rem;

  /* background image */
  --height-Bg-img: 50vh;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
   /* Debugging horizontal scroll  */
   /* box-shadow: 0 0 0 3px red inset;  */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, var(--main-font) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
/******************** LINKS ********************/
a {
  text-decoration: none !important;
  color: var(--five-color) !important;
}
a:visited {
  color: var(--five-color) !important;
}
a:hover {
  color: var(--four-color) !important;
}
/******************** IMAGES ********************/
img {
  border-radius: var(--br);
  max-width: 100%;
}
.bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.setImages {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.setImages img {
  margin: var(--marginBlock);
}
/******************** TITLES ********************/
h1,
h2,
h3 {
  letter-spacing: var(--ls);
}
h1 {
  font-size: var(--fs-6) !important;
  color: var(--five-color);
}
h2 {
  font-size: var(--fs-5) !important;
  color: var(--five-color);
  font-weight: 700 !important;
}
/******************** LISTS ********************/
ul {
  list-style-type: none;
}
/******************** BUTTONS ********************/
.Button {
  color: var(--five-color);
  background-color: rgba(95, 79, 79, 0.7);
  border-radius: var(--br);
  border: 2px var(--main-color) solid;
  padding: 5px;
}
.Button:hover {
  border: 2px var(--five-color) solid;
}
/******************** TABLES ********************/
.tab-content {
  min-height: 500px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--main-color);
}
.containerText {
  max-width: 1200px;
  margin: var(--marginBlock) auto;
}
.custom-tabs-fill .nav-link {
  margin: 0;
}
.tabs {
  background-color: var(--main-color);
}
.tab.nav-link{
  color: var(--four-color) !important;}

/******************** OTHERS ********************/
blockquote {
  font-family: var(--ter-font);
  font-weight: 800;
}

/******************** HEADBAND ********************/
.bandeau {
  background-color: var(--main-color);
  color: var(--five-color);
  border: 1px solid var(--five-color);
  padding: 3.5em 1.25rem 1.25rem 3.5rem;
}
.accroche {
  font-family: var(--ter-font);
  font-size: var(--fs-2);
}
.custom-hr {
  border-width: 2px;
  border-color: var(--five-color);
  width: 10%;
}
/******************** IMAGE-PARAGRAPH COUPLE  ********************/
.image-paragraph {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
  color: var(--five-color);
}
.cadre-paragraph {
  /* border: var(--borderBlock); */
  /* border-radius: var(--br); */
  padding: var(--paddingBlock);
  font-weight: 400;
  margin: var(--marginBlock) 0;
}
.image-paragraph.right {
  flex-direction: row-reverse;
}
.image-paragraph.right img,
.image-paragraph.left img,
.image-paragraph.bottom img,
.image-paragraph.top img {
  margin: var(--marginBlock);
}

.image-paragraph.left {
  flex-direction: row;
}
.image-paragraph.bottom {
  flex-direction: column-reverse;
}
.image-paragraph.top {
  flex-direction: column;
}
